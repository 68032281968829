import "../assets/css/community.css";
import chevron_downIcon from "../assets/img/modals/chevron-down.svg"
import chevron_down_greyIcon from "../assets/img/chevron-down-gray.svg"
import tickIcon from "../assets/img/tick.svg"
import addUserIcon from "../assets/img/add-user.svg"
import exportIcon from "../assets/img/export-icon.svg"
import dotsIcon from "../assets/img/dots.svg"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from "react";
import * as $ from "jquery"
import { ChevronRight } from "../utils/svg";
import PageHeader from "../components/page-header";
import Skeleton from "react-loading-skeleton";
import SideBar from "../components/side-bar";
import getAudience, { getDefaultAudience } from "../services/getAudience";
import { useSelector } from "react-redux";
import { apiUrl } from "../utils/url";
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../components/loader";
import { audiencePosts } from "../services/postRequests/audience";
import ImportModal from "../components/audience/import-modal";
import setDateFormat, { formatISODate } from "../utils/setDateFormat";
import CommunityFooter from "../components/community/footer";
import HeaderSearch from "../components/community/headerSearch";
import ConfirmationModal from "../components/confirmation-modal";
import NewSideBar from "../components/side-bar/new-sidebar";
import EmptyState from "./empty-state";
import { useAlert } from "../contexts/alertContext";

let params = []
const Audience = () => {
    const [loading, setloading] = useState(true);
    const { slug } = useSelector(state => state?.slugReducer);
    const [allAudience, setallAudience] = useState([]);
    const [allDefaultAudience, setallDefaultAudience] = useState([]);
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null, deleteAll: null});
    const [isConfirmMessage, setisConfirmMessage] = useState(false)
    const [showImportModal, setshowImportModal] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [newTag, setNewTag] = useState('');
    const [tags, setTags] = useState([]);
    const [checkedTags, setCheckedTags] = useState([]);
    const [bulkcheckedTags, setBulkcheckedTags] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]); 
    const audienceTagsReducer = useSelector(state => state?.audienceTagsReducer);
    const { setAlert } = useAlert();
    const [filter, setfilter] = useState({
        page: 1,
        orderBy: null,
        filter: null,
        orderType: "desc",
        count: localStorage?.audience_count ?? 10
    })

    $(document).on('click', '.filter-dropdown-menu li a', function () {
        $('.filter-dropdown-menu li a').removeClass('active');
        $(this).addClass('active');
        $('.filter-dropdown-menu-selected').text($(this).attr('data-value'));
    });

    useEffect(() => {
        // showLoader()
        if (slug) {
            getDefaultAudience(slug).then((response) => {
                setloading(false)
                setallDefaultAudience(response);
                // hideLoader()
            })
        }
    }, [slug]);
    useEffect(() => {
        if (Array.isArray(allAudience?.model?.data) && allAudience?.model?.data.length > 0) {
            let audienceTags = allAudience?.model?.data[0]?.project?.audience_tags;
            audienceTags = JSON?.parse(audienceTags);
            setTags(audienceTags);
            setBulkcheckedTags(allAudience?.commonTag);
        const allCheckedTags = allAudience?.model?.data?.map((val) => {
            try {
                const parsedTags = JSON?.parse(val?.tags);
                return { id: val.id, tags: parsedTags }; // Include id of the audience member
            } catch (error) {
                console.error('Error parsing JSON for checked tags:', error);
                return null;
            }
        });

        // Flatten the array of arrays into a single array of objects containing audience id and tags
        const flattenedCheckedTags = allCheckedTags.flat();

        // Create an object to hold the checked tags for each audience member
        const checkedTagsMap = {};
        flattenedCheckedTags.forEach((item) => {
            if (item) {
                checkedTagsMap[item?.id] = item?.tags;
            }
        });

        // Set the checked tags state
        setCheckedTags(checkedTagsMap);
    }
    }, [allAudience?.model?.data]);
    useEffect(() => {
        params = [];
        if (slug) {
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    if (filter[key])
                        params.push(`${[key]}=${filter[key]}`)
                }
            };
            if (filter?.page) callAudience()
        }
    }, [slug, filter]);

    const selectFilter = (e) => setfilter({ ...filter, filter: e.target.dataset?.value !== "All" ? e.target.dataset?.value?.toLowerCase() : null })
    const selectTagFilter = (e) => setfilter({ ...filter, filterTag: e.target.dataset?.value !== "All" ? e.target.dataset?.value?.toLowerCase() : null })
    const callAudience = () => {
        // showLoader()
        // let getRows;
        // if (window.innerHeight < 1000) {
        //     getRows = 12;
        // }else if (window.innerHeight >= 1100 && window.innerHeight <= 1200) {
        //     getRows = 13;
        // }else if (window.innerHeight >= 1200 && window.innerHeight <= 1300) {
        //     getRows = 14;
        // }else if (window.innerHeight >= 1300 && window.innerHeight <= 1400) {
        //     getRows = 15;
        // }else if (window.innerHeight >= 1400 && window.innerHeight <= 1500) {
        //     getRows = 22;
        // }else if (window.innerHeight >= 1500 && window.innerHeight <= 1600) {
        //     getRows = 24;
        // }else{
        //     getRows = 27;
        // }
        // params.push(`pagination=${[getRows]}`)
        getAudience(slug, params?.join("&")).then((response) => {
            setloading(false);
            setallAudience(response);
            // hideLoader();
        })
    }

    const filterToggler = (e, value) => {
        setfilter({
            ...filter,
            "orderBy": !$(e.target).hasClass("sortable-col-desc") ? value : value,
            orderType: !$(e.target).hasClass("sortable-col-desc") ? "desc" : "asc"
        })

        if ($(e.target).hasClass('sortable-col-desc')) {
            $('.sortable-col').removeClass('sortable-col-desc');
        }
        else {
            $('.sortable-col').removeClass('sortable-col-desc');
            $(e.target).addClass('sortable-col-desc');
        }
    };

    const unsubscribeAudience = async (id) => {
        showLoader();
        const api = apiUrl + "/community/subscribers/unsubscribe";
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const form_data = new FormData();
        form_data.append("subscriber", id)
        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: form_data
        }
        audiencePosts(setAlert, api, hideLoader, setOptions).then(() => callAudience())
    };

    const deleteAudience = (id) => {
        // showLoader();
        const api = apiUrl + "/community/subscribers/delete/" + id;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "DELETE",
            headers: myHeaders,
        }
        audiencePosts(setAlert, api, hideLoader, setOptions).then(() => {
            setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
            callAudience()
        }).catch(() => {
                setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
                hideLoader()
            })
    }
    const copyEmail = (email) => navigator.clipboard.writeText(email).then((r => setAlert({ type: 'success', title: `Email Copied to Clipboard` }) ))

    const openImportModal = () => setshowImportModal(true)
    const handleHide = () => setshowImportModal(false);

    const exportCSV = () => {
        showLoader();
        const api = apiUrl + "/community/subscribers/export";
        const formdata = new FormData();
        formdata.append("project", slug)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata
        }
        audiencePosts(setAlert, api, hideLoader, setOptions, "export")
    }

    const deleteAllAudience = (ids) => {
        // showLoader();
        const api = apiUrl + "/community/subscribers/delete-all";
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        var formData = new FormData();
        formData.append('slug',slug);
        const inputs = document.querySelectorAll('input[name="select-one"]:checked');
        inputs.forEach(input => {
            formData.append("id[]", input.dataset.id);
        });
        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        }
        audiencePosts(setAlert, api, hideLoader, setOptions).then(() => {
            setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
            inputs.forEach(input => {
                input.checked = false;
            });
            const selectAllCheckbox = document.querySelector('input[name="select-all"]');
            if (selectAllCheckbox) {
                selectAllCheckbox.checked = false;
            }
            setSelectAll(false)
            setSelectedRows([]);
            callAudience()
        }).catch(() => {
                setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
                hideLoader()
            })
    }
    const handleTagChange = (e, val, val_id) => {
            e.stopPropagation()
            const isChecked = e.target.checked;

            // Get the existing checked tags for the current audience member
            const existingCheckedTags = checkedTags[val_id] || [];
        
            // Create a new array with the updated checked tags
            const updatedCheckedTags = isChecked
                ? [...existingCheckedTags, val]
                : existingCheckedTags.filter(tag => tag.tag !== val.tag);
        
            setCheckedTags(prevState => ({
                ...prevState,
                [val_id]: updatedCheckedTags
            }));
            const api = apiUrl + "/community/subscribers/tag-change";
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            formdata.append('slug',slug)
            formdata.append('value',JSON.stringify(val))
            formdata.append('subscriber_id',e?.currentTarget?.dataset?.id)
            formdata.append('checked',e.target.checked ? 1 : 0)
            const setOptions = {
                method: "POST",
                headers: myHeaders,
                body:formdata
            }
            audiencePosts(setAlert, api, hideLoader, setOptions).then(() => {
            //   callAudience()
            }).catch(() => {
                hideLoader()
            })
    }
    const handleInputChange = (e) => {
      setNewTag(e.target.value);
    };
  
    const handleAddTag = () => {
        if (newTag.trim() !== '' && (!tags || !tags.includes(newTag))) {
            const newColor = generateRandomColor();
            const updatedTags = tags ? [...tags, { tag: newTag, color: newColor }] : [{ tag: newTag, color: newColor }];
            setTags(updatedTags);
            setNewTag('');
            const api = apiUrl + "/community/subscribers/add-tag";
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            formdata.append('slug',slug)
            formdata.append('tag',newTag)
            formdata.append('color',newColor)
            const setOptions = {
                method: "POST",
                headers: myHeaders,
                body:formdata
            }
            audiencePosts(setAlert, api, hideLoader, setOptions).then(() => {
            //   callAudience()
            //   setSelectAll(false)
            }).catch(() => {
                hideLoader()
            })
        }
    };
    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const handleBulkTagChange = (e,val) => {
        e.stopPropagation();    
        const api = apiUrl + "/community/subscribers/bulk-add-tag";
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            formdata.append('slug',slug)
            formdata.append('value',JSON.stringify(val))
            formdata.append('checked',e.target.checked ? 1 : 0)
            const inputs = document.querySelectorAll('input[name="select-one"]:checked');
            inputs.forEach(input => {
                formdata.append("id[]", input.dataset.id);
            });
            const setOptions = {
                method: "POST",
                headers: myHeaders,
                body:formdata
            }
            audiencePosts(setAlert, api, hideLoader, setOptions).then(() => {
                callAudience();
            }).catch(() => {
                hideLoader()
            })
    }
    const handleCheckboxChange = (e, id) => {
        if (id === 'select-all') {
            const inputs = document.querySelectorAll('input[name="select-one"]');
            inputs.forEach(input => {
                input.checked = e.target.checked;
                if (e.target.checked) {
                    setSelectedRows(selectedRows => [...selectedRows, input?.dataset?.id]);
                } else {
                    setSelectedRows([]);
                }
            });
        }  else {
            if (e.target.checked) {
                setSelectedRows([...selectedRows, id]);
            } else {
                const selectAllCheckbox = document.querySelector('input[name="select-all"]');
                if (selectAllCheckbox) {
                    selectAllCheckbox.checked = false;
                }
                const updatedSelectedRows = selectedRows.filter(rowId => rowId !== id);
                setSelectedRows(updatedSelectedRows);
            }
        }    
    };
    return (
        <div id="wrapper">
            <PageHeader />
            <div class="community-main new-sidebar">
                <NewSideBar from={"audience"}/>
                {/* <SideBar from="audience" /> */}

                <section class="community-section pb-0">

                    <div class="section-header sec-head-u">
                        <h1 class="mb-0">Audience </h1>
                        <div class="d-md-flex d-none align-items-center justify-content-end">
                            <HeaderSearch filter={filter} setfilter={setfilter} name="audience" />

                            <button class="btn btn-light btn-semi-rounded import-btn px-3 py-2 me-2 ms-2 d-flex align-items-center justify-content-center btn-import" onClick={openImportModal}>
                                <img src={addUserIcon} width="14px" alt="" />
                                <span>Import</span>
                            </button>

                            <button class="btn btn-primary btn-semi-rounded import-btn px-3 py-2 d-flex align-items-center justify-content-center Export-btn-u" onClick={exportCSV} disabled={allAudience?.model?.data?.length > 0 ? false : true}>
                                <span className="export-u">Export</span>
                                <img class="ms-2" src={exportIcon} width="14px" alt="" />
                            </button>
                        </div>
                    </div>
                    {!allAudience?.model?.data?.length > 0?
                        <div class="section-main sec-main-u p-0 position-relative">
                            <EmptyState from={"audience"} firstText={'Someone’s Popular!'} secondText={"Start building your email list by adding the signup form to your page or by importing your existing contacts."} buttonText={"Import"} clickedFunction={openImportModal}/>
                        </div>
                    :
                    <div class="section-main sec-main-u">
                      

                        <div class="d-flex table-responsive">
                            <table class="table" id="myTable" border="0">
                                <thead class="d-md-table-header-group dashview-u">
                                    <tr>                                        
                                        
                                        <th className={`aud-leftborder thead-padd aud-text ${selectAll ? ' selected':''}`}>
                                            {allAudience?.model?.data?.length > 0 &&
                                            // <span className="checkbox me-2">
                                            //     <input type="checkbox" class="" id="checkbox-select-all" checked={selectAll}name="select-all" onChange={(e) => {setSelectAll(e.target.checked)}}/>
                                            //     <label htmlFor="checkbox-select-all" id="label"></label>
                                            // </span>
                                            <label className="custom-checkbox checkbox">
                                                <input type="checkbox" name="select-all" data-id="select-all" onChange={(e) => handleCheckboxChange(e, 'select-all')}/>
                                                <span className="checkmark"></span>
                                            </label>
                                            }
                                            {selectedRows?.length >0 ?
                                            <>
                                                <button className="delete-all-btn" onClick={() => setconfirmationModalShow({show: true, text: "Are you sure you want to delete All audience?", id: "", callBackTo: "delete", deleteAll: true})}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 14 16" fill="none">
                                                        <path d="M8.77364 0.564453C9.54452 0.564453 10.1761 1.17108 10.2319 1.94053L10.2359 2.05151V3.53857H12.4293C12.8331 3.53857 13.1605 3.87146 13.1605 4.2821C13.1605 4.6634 12.8782 4.97767 12.5146 5.02062L12.4293 5.02562H12.3789L11.8418 12.6728C11.736 14.179 10.54 15.3569 9.07236 15.4313L8.92474 15.435H4.23572C2.75088 15.435 1.51038 14.3057 1.33282 12.8223L1.31862 12.6728L0.780849 5.02562H0.731137C0.327341 5.02562 0 4.69274 0 4.2821C0 3.90079 0.282235 3.58652 0.645858 3.54357L0.731137 3.53857H2.92455V2.05151C2.92455 1.26756 3.52104 0.625299 4.27767 0.568532L4.38682 0.564453H8.77364ZM10.9122 5.02562H2.24749L2.77719 12.5669C2.82639 13.2672 3.34705 13.8256 4.00996 13.9303L4.1217 13.9435L4.23572 13.948H8.92474C9.65355 13.948 10.2646 13.4038 10.3708 12.6822L10.3833 12.5669L10.9122 5.02562ZM5.11796 7.25621C5.49291 7.25621 5.80195 7.54324 5.84418 7.91303L5.84909 7.99974V10.9739C5.84909 11.3845 5.52175 11.7174 5.11796 11.7174C4.743 11.7174 4.43396 11.4304 4.39173 11.0606L4.38682 10.9739V7.99974C4.38682 7.5891 4.71416 7.25621 5.11796 7.25621ZM8.0425 7.25621C8.41745 7.25621 8.72649 7.54324 8.76873 7.91303L8.77364 7.99974V10.9739C8.77364 11.3845 8.4463 11.7174 8.0425 11.7174C7.66755 11.7174 7.35851 11.4304 7.31627 11.0606L7.31137 10.9739V7.99974C7.31137 7.5891 7.63871 7.25621 8.0425 7.25621ZM8.77364 2.05151H4.38682V3.53857H8.77364V2.05151Z" fill="#FF2020"/>
                                                    </svg>
                                                    <span>Delete</span>
                                                </button>
                                                
                                                <button className="ms-1 add-tags-dropdown add-tags-dropdown-bulk dropdown-toggle noselect border-0" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => e.stopPropagation()} id="bulk-add-tags-dropdown">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 14 16" fill="none">
                                                    <path d="M13 7.33325L7.93726 2.27051C7.59135 1.92461 7.4184 1.75166 7.21657 1.62797C7.03762 1.51831 6.84254 1.43751 6.63846 1.38851C6.40829 1.33325 6.16369 1.33325 5.67452 1.33325L3 1.33325M1 5.79992L1 7.11626C1 7.44238 1 7.60544 1.03684 7.75889C1.0695 7.89494 1.12337 8.025 1.19648 8.1443C1.27894 8.27885 1.39424 8.39416 1.62484 8.62476L6.82484 13.8248C7.35286 14.3528 7.61687 14.6168 7.92131 14.7157C8.1891 14.8027 8.47757 14.8027 8.74536 14.7157C9.04979 14.6168 9.31381 14.3528 9.84183 13.8248L11.4915 12.1751C12.0195 11.6471 12.2835 11.383 12.3825 11.0786C12.4695 10.8108 12.4695 10.5224 12.3825 10.2546C12.2835 9.95013 12.0195 9.68611 11.4915 9.15809L6.62484 4.29142C6.39424 4.06082 6.27894 3.94552 6.14438 3.86307C6.02508 3.78996 5.89502 3.73609 5.75898 3.70343C5.60553 3.66659 5.44247 3.66659 5.11634 3.66659H3.13333C2.3866 3.66659 2.01323 3.66659 1.72801 3.81191C1.47713 3.93974 1.27316 4.14372 1.14532 4.3946C1 4.67981 1 5.05318 1 5.79992Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <span className="bulk-tag-text">Edit Tags</span>
                                                </button>
                                                <ul className="audience-tags-dropdown audience-tags-dropdown-bulk dropdown-menu" aria-labelledby="bulk-add-tags-dropdown">
                                                    <li onClick={(e) => e.stopPropagation()} className="dropdown-item w-100 d-flex align-items-center jusitfy-content-start search-bar search-u pe-0 overflow-hidden"><input type="text" placeholder="Add new tag" className="form-control zaap-form-control borderless d-md-flex u-input" value={newTag} onChange={handleInputChange}/>
                                                        <button type="button" style={{fontSize:"13px"}} className="btn btn-primary" onClick={handleAddTag}>Add</button>
                                                    </li>
                                                    {tags?.map((value, index) => {
                                                       const isChecked = bulkcheckedTags?.some(tag =>  tag?.tag === value?.tag); 
                                                        return (
                                                            <li key={index} className="dropdown-item tags-option" onClick={(e) => e.stopPropagation()}>
                                                            <input
                                                                onChange={(e)=>handleBulkTagChange(e,value)}
                                                                className="tag-checked "
                                                                type="checkbox"
                                                                id={`tag-checkbox-bulk-${index}`}
                                                                style={{ display: 'none' }}
                                                                checked={isChecked}
                                                            />
                                                            <label onClick={(e) => e.stopPropagation()} style={{ backgroundColor: value?.color }} className="small-box" htmlFor={`tag-checkbox-bulk-${index}`}>
                                                            </label>
                                                            <span onClick={() => {
                                                                const checkbox = document.getElementById(`tag-checkbox-bulk-${index}`);
                                                                if (checkbox) {
                                                                    checkbox.click(); // Trigger checkbox click event
                                                                }
                                                            }} className={`cursor-pointer ${isChecked ? 'checked-tag':''}`}>{value?.tag}</span>
                                                        </li>)
                                                    })}
                                                </ul>
                                            </>
                                                :
                                                'Name'
                                            }
                                        </th>
                                        <th className={`thead-padd getwidth2 aud-text ${selectAll ? " invisible": ''}`}>Email Address</th>
                                        <th className={`thead-padd hide-for-tab ${selectAll ? " invisible": ''}`}>
                                            <div class="filter-dropdown justify-content-start ps-0 p-0 dropdown-toggle noselect d-tab-none" id="filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="">Status:</span>
                                                <span class="filter-dropdown-menu-selected ms-2 font-weight-500">All</span>
                                                <img src={chevron_downIcon} width="10px" alt="" class="ms-1" />
                                            </div>

                                            <ul class="dropdown-menu filter-dropdown-menu" aria-labelledby="filter-dropdown">
                                                <li>
                                                    <a class="dropdown-item active" data-value="All" onClick={selectFilter}>
                                                        <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="All" onClick={selectFilter}>
                                                            <img src={tickIcon} alt="" data-value="All" onClick={selectFilter} />&nbsp;&nbsp;All</span>
                                                        <small class="text-dark" data-value="All" onClick={selectFilter}>{allDefaultAudience?.All}</small>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" data-value="Subscribers" onClick={selectFilter}>
                                                        <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="Subscribers" onClick={selectFilter}>
                                                            <img src={tickIcon} alt="" data-value="Subscribers" onClick={selectFilter} />&nbsp;&nbsp;Subscribers</span>
                                                        <small class="text-dark" data-value="Subscribers" onClick={selectFilter}>{allDefaultAudience?.Subscribers}</small>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" data-value="Unsubscribed" onClick={selectFilter}>
                                                        <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="Unsubscribed" onClick={selectFilter}>
                                                            <img src={tickIcon} alt="" data-value="Unsubscribed" onClick={selectFilter} />&nbsp;&nbsp;Unsubscribed</span>
                                                        <small class="text-dark" data-value="Unsubscribed" onClick={selectFilter}>{allDefaultAudience?.Unsubscribed}</small>
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a class="dropdown-item" data-value="Customers" onClick={selectFilter}>
                                                        <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="Customers" onClick={selectFilter}>
                                                            <img src={tickIcon} alt="" data-value="Customers" onClick={selectFilter} />&nbsp;&nbsp;Customers</span>
                                                        <small class="text-muted" data-value="Customers" onClick={selectFilter}>{allDefaultAudience?.Customers}</small>
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </th>
                                        <th class={`thead-padd resp d-none-u ${selectAll ? " invisible": ''}`} onClick={(e) => filterToggler(e, "date_joined")}>
                                            <span >
                                                Date Joined&nbsp;&nbsp;
                                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                    <path d="M2.46806 0.709457C2.7584 0.40772 3.24135 0.40772 3.53169 0.709457L5.79205 3.05849C6.24323 3.52737 5.91094 4.30828 5.26023 4.30828H0.739516C0.0888117 4.30828 -0.243484 3.52737 0.207698 3.05849L2.46806 0.709457Z" fill="#6B6B6B" fill-opacity="0.5"/>
                                                    <path d="M3.53194 9.2903C3.2416 9.59204 2.75865 9.59204 2.46831 9.2903L0.20795 6.94127C-0.243232 6.47238 0.089063 5.69148 0.739767 5.69148L5.26048 5.69148C5.91119 5.69148 6.24348 6.47238 5.7923 6.94127L3.53194 9.2903Z" fill="#6B6B6B" fill-opacity="0.5"/>
                                                </svg>
                                            </span>
                                        </th>
                                        <th class={`thead-padd resp d-none-u ${selectAll ? " invisible": ''}`} onClick={(e) => filterToggler(e, "revenue")}>
                                            <span >
                                                Revenue&nbsp;&nbsp;
                                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                    <path d="M2.46806 0.709457C2.7584 0.40772 3.24135 0.40772 3.53169 0.709457L5.79205 3.05849C6.24323 3.52737 5.91094 4.30828 5.26023 4.30828H0.739516C0.0888117 4.30828 -0.243484 3.52737 0.207698 3.05849L2.46806 0.709457Z" fill="#6B6B6B" fill-opacity="0.5"/>
                                                    <path d="M3.53194 9.2903C3.2416 9.59204 2.75865 9.59204 2.46831 9.2903L0.20795 6.94127C-0.243232 6.47238 0.089063 5.69148 0.739767 5.69148L5.26048 5.69148C5.91119 5.69148 6.24348 6.47238 5.7923 6.94127L3.53194 9.2903Z" fill="#6B6B6B" fill-opacity="0.5"/>
                                                </svg>
                                            </span>
                                        </th>
                                        <th className={`thead-padd resp d-none-u ${selectAll ? " invisible": ''}`}>
                                            <div class="filter-dropdown justify-content-start ps-0 p-0 dropdown-toggle noselect d-tab-none" id="filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="">Tags:</span>
                                                <span className="ms-2">{filter?.filterTag ?? 'All'}</span>
                                                <img src={chevron_downIcon} width="10px" alt="" class="ms-1" />
                                            </div>
                                            {audienceTagsReducer?.length > 0 &&
                                            <ul class="dropdown-menu filter-dropdown-menu" aria-labelledby="filter-dropdown">
                                                 <li>
                                                    <a class="dropdown-item active" data-value="All" onClick={selectTagFilter}>
                                                        <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="All" onClick={selectFilter}>
                                                        <img src={tickIcon} alt="" data-value="All" onClick={selectTagFilter} />All</span>
                                                    </a>
                                                </li>
                                                {audienceTagsReducer?.map((val,index) => (
                                                        <li>
                                                            <a class="dropdown-item" data-value={val?.tag} onClick={selectTagFilter}>
                                                                <span class="me-5 d-flex align-items-center justify-content-betwen" data-value={val?.tag}>
                                                                <img src={tickIcon} alt="" data-value={val?.tag}/>{val?.tag}</span>
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            }
                                        </th>
                                        <th className={`th-end-u ${selectAll ? " invisible": ''}`}></th> 
                                    </tr>
                                </thead>

                                {
                                    loading ?
                                        <tbody>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                        <span class="subscriber-name-icon me-2 d-md-flex d-none">A</span>
                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                            <span class="d-none">Adam</span>
                                                            <Skeleton height={40}
                                                                borderRadius="10px"
                                                                className="d-md-none d-block"
                                                                highlightColor="#ffffff"
                                                                duration={4}
                                                            />
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <Skeleton height={40}
                                                        className="mb-1 me-2 mt-1 badge"
                                                        borderRadius="10px"
                                                        highlightColor="#ffffff"
                                                        duration={4}
                                                    />
                                                </td>
                                                <td class="d-md-table-cell d-none">

                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">

                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                            {
                                                allAudience?.model?.data?.length > 0 && (
                                                    allAudience?.model?.data?.map((val, index) => {
                                                    const val_id = val?.id; 
                                                    return(
                                                        <tr key={index}>
                                                            <td>
                                                                <label className="custom-checkbox checkbox">
                                                                    <input type="checkbox" name="select-one" data-id={val.id} onChange={(e) => handleCheckboxChange(e, val.id)}/>
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start">
                                                                    <span class="subscriber-name-icon me-2 d-md-flex d-none">{val?.name.substring(0, 1).toUpperCase()}</span>
                                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                                        <span className="prodname">{val?.name}</span>
                                                                        {/* <span class="d-md-none d-block">{val?.email}</span> */}
                                                                    </span>
                                                                </p>
                                                            </td>
                                                            <td class="d-md-table-cell prodname">{val?.email}</td>
                                                            <td class="d-md-table-cell d-none d-tab-none p-0">
                                                                {val?.subscribed == 1 ?
                                                                    (<p class="mb-0 status-badge">
                                                                        <span style={{ backgroundColor: "#1188FF" }} className="small-box" htmlFor={`tag-checkbox-${val_id}-${index}`}>
                                                                        </span>
                                                                        <span>
                                                                            Subscribed
                                                                        </span>
                                                                    </p>) :
                                                                    (<p class="mb-0 status-badge">

                                                                        <span style={{ backgroundColor: "#FF1451" }} className="small-box" htmlFor={`tag-checkbox-${val_id}-${index}`}>
                                                                        </span>
                                                                        <span>Unsubscribed</span>
                                                                    </p>)
                                                                }
                                                            </td>
                                                            {/* <td class="d-md-table-cell d-none thead-padd">{setDateFormat(val?.created_at)}</td> */}
                                                            <td class="d-md-table-cell d-none prodname">{setDateFormat(val?.created_at,true)}</td>
                                                            <td class="d-md-table-cell d-none prodname">${val?.revenue ?? 0}</td>
                                                            <td>
                                                                <div className="d-flex flex-wrap gap-1">
                                                                    {checkedTags[val_id] && checkedTags[val_id].length > 0 && (
                                                                        checkedTags[val_id].map((val, index) => (
                                                                            <p className="tags-badge mb-0">
                                                                                <span style={{ backgroundColor: val?.color }} className="small-box" htmlFor={`tag-checkbox-${val_id}-${index}`}>
                                                                                </span>
                                                                                <span key={index}>{val.tag}</span>
                                                                            </p>
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end dropdown-subscribers" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                    <img src={dotsIcon} width="" alt="" />
                                                                </div>
                                                                <ul class="dropdown-menu" aria-labelledby="table-action-dropdown">
                                                                    {val?.subscribed == 1 ?
                                                                        <li><a class="dropdown-item" onClick={() => unsubscribeAudience(val?.id)}><span class="me-5">Unsubscribe</span><ChevronRight /></a></li>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    <li><a class="dropdown-item" onClick={() => copyEmail(val?.email)}><span class="me-5">Copy email</span><ChevronRight /></a></li>
                                                                    <li onClick={(e) => e.stopPropagation()} class="add-tags-dropdown dropdown-toggle noselect d-md-flex d-none" id="add-tags-dropdown" data-bs-toggle="dropdown" aria-expanded="false"><a className="dropdown-item"><span class="me-5">Edit Tag</span><ChevronRight /></a></li>
                                                                    <ul className="audience-tags-dropdown dropdown-menu" aria-labelledby="add-tags-dropdown">
                                                                        <li onClick={(e) => e.stopPropagation()} className="dropdown-item w-100 d-flex align-items-center jusitfy-content-start search-bar search-u pe-0 overflow-hidden"><input type="text" placeholder="Add new tag" className="form-control zaap-form-control borderless d-md-flex u-input" value={newTag} onChange={handleInputChange}/><button type="button" style={{fontSize:"13px"}} className="btn btn-primary" onClick={handleAddTag}>Add</button></li>
                                                                        {tags?.map((value, index) => {
                                                                            const isChecked = Array.isArray(checkedTags?.[val_id]) && checkedTags?.[val_id]?.some(tag =>  tag?.tag === value?.tag);                                                                        return (
                                                                                <li key={index} className="dropdown-item tags-option" onClick={(e) => e.stopPropagation()}>
                                                                                <input
                                                                                    data-id={val?.id}
                                                                                    onChange={(e)=>handleTagChange(e,value,val?.id)}
                                                                                    className="tag-checked "
                                                                                    type="checkbox"
                                                                                    id={`tag-checkbox-${val_id}-${index}`}
                                                                                    style={{ display: 'none' }}
                                                                                    checked={isChecked}
                                                                                />
                                                                                <label onClick={(e) => e.stopPropagation()} style={{ backgroundColor: value?.color }} className="small-box" htmlFor={`tag-checkbox-${val_id}-${index}`}>
                                                                                </label>
                                                                                <span onClick={() => {
                                                                                    const checkbox = document.getElementById(`tag-checkbox-${val_id}-${index}`);
                                                                                    if (checkbox) {
                                                                                        checkbox.click(); // Trigger checkbox click event
                                                                                    }
                                                                                }} className={`cursor-pointer  ${isChecked? 'checked-tag' : ''}`}>{value?.tag}</span>
                                                                            </li>)
                                                                        })}
                                                                    </ul>
                                                                    <li><a class="dropdown-item theme-active semi-bold" onClick={() =>
                                                                        setconfirmationModalShow({
                                                                            show: true, text: "Are you sure you want to delete this User?", id: val?.id, callBackTo: "delete", deleteAll: false
                                                                        })} ><span class="me-5">Delete Contact</span><ChevronRight /></a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )})
                                                )}

                                        </tbody>

                                }
                            </table>
                        </div>


                        <div class="section-footer d-flex align-items-center justify-content-between">
                            <CommunityFooter from={"audience"} loading={loading} filter={filter} setfilter={setfilter} total={allAudience?.model?.total} last_page={allAudience?.model?.last_page} current_page={allAudience?.model?.current_page}/>
                        </div>

                    </div>
                    }
                </section>
            </div>
            <ImportModal callAudience={callAudience} tags={tags} show={showImportModal} setshowImportModal={setshowImportModal} handleHide={handleHide} />
            {confirmationModalShow?.show && <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} setisConfirmMessage={setisConfirmMessage} callback={confirmationModalShow?.deleteAll ? deleteAllAudience : deleteAudience} />}

        </div>
    )
}
export default Audience